<template>
  <article class="notice">
    <pc-notice-list v-if="!isMobileOnly"/>
    <mobile-notice-list v-else/>
  </article>
</template>

<script>
import { defineComponent } from 'vue'
import { isMobileOnly } from 'mobile-device-detect'
import PcNoticeList from '../../components/pc/notice/NoticeList'
import MobileNoticeList from '../../components/mobile/notice/NoticeList'
export default defineComponent({
  name: 'NoticeList',
  components: {
    PcNoticeList,
    MobileNoticeList
  },
  setup() {

    return {
      isMobileOnly,
    }
  }
})
</script>

<style scoped>

</style>
