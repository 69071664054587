<template>
  <div class="layout small">
    <section-title>
      <template v-slot:title>
        새소식
      </template>
    </section-title>
    <table class="table">
      <colgroup>
        <col style="width: 10%">
        <col style="width: 66%">
        <col style="width: 24%">
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th>제목</th>
          <th>작성시간</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(notice, idx) in noticeListData" :key="idx"
          @click="goDetail(notice.id)"
        >
          <td class="text-center">
            <b>{{ notice.idx }}</b>
          </td>
          <td>
            {{ `${getTypeStr(notice.type)}${notice.title}` }}
          </td>
          <td class="text-center">
            {{ convertYmd(notice.createdAt) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="showDataLoading"
      class="loading-area"
    >
      <span class="loading">Loading..</span>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../../layout/SectionTitle'
import { onMounted, onUnmounted } from 'vue'
import useNoticeList from '@/mixins/useNoticeList'

export default {
  name: 'NoticeList',
  components: {
    SectionTitle
  },
  setup () {
    const { noticeListData, nowPage, showDataLoading, fetchListData, goDetail, convertYmd, getTypeStr, handleScroll } = useNoticeList()

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      fetchListData({ page: nowPage.value, pageSize: 30 })
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    })

    return {
      noticeListData,
      showDataLoading,
      goDetail,
      convertYmd,
      getTypeStr
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  border-top: 2px solid $black2;
  color: $black2;

  tr {
    height: 48px;
    th {
      letter-spacing: -0.01em;
      font-size: 14px;
    }
    td {
      letter-spacing: -0.01em;
      padding: 16px;
    }
  }
  thead {
    tr {
      text-align: center;
      background: $vary-light-gray2;
    }
  }
  tbody {
    tr {
      cursor: pointer;
      box-shadow: inset 0px -1px 0px $vary-light-gray3;
    }
  }
}
</style>
