<template>
  <div class="notice-list">
    <div
      v-for="(notice, idx) in noticeListData" :key="idx"
      class="notice-list__item"
      @click="goDetail(notice.id)"
    >
      <mark class="notice-list__category">
        {{ notice.idx }}
      </mark>
      <p class="notice-list__subject">
        {{ `${getTypeStr(notice.type)}${notice.title}` }}
      </p>
      <small class="notice-list__date">
        {{ convertYmd(notice.createdAt) }}
      </small>
    </div>
  </div>
  <div
    v-if="showDataLoading"
    class="loading-area"
  >
    <span class="loading">Loading..</span>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
import useNoticeList from '@/mixins/useNoticeList'

export default {
  name: 'NoticeList',
  components: {
  },
  setup () {
    const { noticeListData, nowPage, showDataLoading, fetchListData, goDetail, convertYmd, getTypeStr, handleScroll } = useNoticeList()

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      fetchListData({ page: nowPage.value, pageSize: 30 })
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    })

    return {
      noticeListData,
      showDataLoading,
      goDetail,
      convertYmd,
      getTypeStr
    }
  }
}
</script>

<style scoped lang="scss">
.notice-list {
  &__item {
    letter-spacing: -0.01em;
    box-shadow: inset 0px -1px 0px $vary-light-gray3;
    padding: 16px 0 20px;
    &:last-of-type {
      box-shadow: none;
    }
    & + .loading-area {}
  }
  &__category {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: none;
    color: $black2;
    display: block;
    margin-bottom: 8px;
  }
  &__subject {
    color: $black2;
    font-size: 12px;
    line-height: 20px;
    @include textClamOne();
  }
  &__date {
    color: $grey2;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
