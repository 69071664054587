import { ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

export default function useNoticeList () {
  const router = useRouter();

  const noticeListData = ref([])
  const nowPage = ref(0)
  const canDataFetch = ref(true)
  const showDataLoading = ref(false)

  const fetchListData = async ({ page = 0, pageSize = 10 }) => {
    try {
      if (canDataFetch.value) {
        showDataLoading.value = true
        canDataFetch.value = false

        const { data: { data, total } } = await axios.get(`${process.env.VUE_APP_API_HOST}/api/common/notices`, {
          params: {
            page,
            pageSize
          }
        })
        const addedData = data.map((notice, idx) => {
          return {
            ...notice,
            idx: total - (page * (data.length || 0)) - idx
          }
        }, [])
        noticeListData.value = noticeListData.value.concat(addedData)
        nowPage.value = page

        canDataFetch.value = noticeListData.value.length < total
        showDataLoading.value = false
      }
    } catch (e) {
      canDataFetch.value = false
      showDataLoading.value = false
      alert('데이터 로드 실패.')
    }
  }

  const goDetail = (noticeId) => {
    router.push(`/notice/detail/${noticeId}`)
  }

  const convertYmd = (date) => {
    if (!date) {
      return ''
    }
    return dayjs(date).format('YYYY-MM-DD')
  }

  const getTypeStr = (type) => {
    return type === '공지' ? '[공지사항]'
           : type === '이벤트' ? '[이벤트]'
           : ''
  }

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const footerHeight = document.querySelector('.footer').clientHeight
    if (scrollTop + clientHeight + footerHeight >= scrollHeight && canDataFetch.value) {
      // 페이지 끝에 도달하면 추가 데이터를 받아온다
      fetchListData(nowPage.value + 1);
    }
  };

  return {
    noticeListData,
    nowPage,
    canDataFetch,
    showDataLoading,
    fetchListData,
    goDetail,
    convertYmd,
    getTypeStr,
    handleScroll
  }
}
